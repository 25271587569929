
import { Component, Vue } from 'vue-property-decorator'
import { OrdinarySeedling } from '@/types/ordinarySeedling.d'
import MaintenanceDialog from '@/components/maintenanceDialog/Index.vue'
import maintenanceConfigDialog from '@/components/maintenanceConfigDialog/Index.vue'
import seedingTypeConfigDialog from '@/components/seedingTypeConfigDialog/Index.vue'
import seedingNoConfigDialog from '@/components/seedingNoConfigDialog/Index.vue'
import { MaintenanceForm } from '@/types/common'

@Component({
  name: 'OrdinarySeedling',
  components: { MaintenanceDialog, maintenanceConfigDialog, seedingTypeConfigDialog, seedingNoConfigDialog }
})
export default class extends Vue {
  private showDialog = false
  private isBatch = false
  private tipsShow = false

  private selects: OrdinarySeedling[] = []
  private currentSeedlingId = ''
  private info = {
    projectId: '',
    plantName: '',
    seedlingType: '',
    treeAge: ''
  }

  private formData: MaintenanceForm | {} = {
    maintainDate: '',
    maintainContent: '',
    resourceList: [],
    maintainStatus: '',
    chestDiameter: '',
    qualityLevel: '',
    valueAssessment: '',
    crownWidth: '',
    treeHeight: '',
    configId: []
  }

  private projectList = []
  private loading = false
  private tableData: OrdinarySeedling[] = []
  private maintainTypeShow = false
  private seedingTypeShow = false
  private seedingNoShow = false
  private typeList = []
  private date = []

  page = 1
  size = 10
  total = 0

  created() {
    this.getTypeList()
    this.getData()
    this.getProtectList()
  }

  // 查询苗木类别列表
  getTypeList() {
    this.$axios.get(this.$apis.record.selectSeedlingTypeByList).then(res => {
      this.typeList = res.list || []
    })
  }

  // 获取含有苗木的项目列表
  getProtectList() {
    this.$axios.get(this.$apis.seedling.selectProjectList).then((res) => {
      this.projectList = res || []
    })
  }

  // 导出
  onExport() {
    const loading = this.$loading({
      lock: true,
      text: '下载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.seedling.exportSeedlingData, { isFamous: '0', ...this.info }).finally(() => {
      loading.close()
    })
  }

  // 添加养护类型
  setMaintainType() {
    this.maintainTypeShow = true
  }

  // 添加苗木类别
  setSeedlingType() {
    this.seedingTypeShow = true
  }

  setSeedlingNo() {
    this.seedingNoShow = true
  }

  getData() {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<OrdinarySeedling> }>(this.$apis.seedling.selectSeedlingByPage, {
      ...this.info,
      startDate: this.date ? (this.date[0] || '') : '',
      endDate: this.date ? (this.date[1] || '') : '',
      isFamous: '0',
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onAdd() {
    this.$router.push({
      name: 'ordinarySeedlingAdd'
    })
  }

  // 批量添加养护记录
  AddMaintenanceBatch() {
    if (this.selects && this.selects.length > 0) {
      this.isBatch = true
      this.tipsShow = true
      this.showDialog = true
    } else {
      this.$message.warning('未选中苗木信息，无法批量维护')
    }
  }

  // 勾选更改
  handleSelectionChange(val: Array<OrdinarySeedling>) {
    this.selects = val
  }

  // 添加养护记录
  addMaintenance(id: string) {
    this.currentSeedlingId = id
    this.showDialog = true
    this.getRecord(id)
  }

  // 获取最新苗木养护档案
  getRecord(id: string) {
    this.$axios.get(this.$apis.record.selectMaintainRecordByPage, {
      page: 1,
      size: 1,
      objectId: id,
      isNewest: 1
    }).then((res) => {
      // console.log('范胡子', res)s
      if (res.list.length) {
        const newRecord = res.list[0]
        this.formData = {
          maintainDate: '',
          maintainContent: '',
          resourceList: [],
          maintainStatus: newRecord.maintainStatus,
          chestDiameter: newRecord.chestDiameter,
          qualityLevel: newRecord.qualityLevel,
          valueAssessment: newRecord.valueAssessment,
          crownWidth: newRecord.crownWidth,
          treeHeight: newRecord.treeHeight,
          configId: []
        }
      }
    })
  }

  saveData(formData: MaintenanceForm) {
    let url = this.$apis.record.insertMaintainRecord
    let info: { objectId?: string; objectIds?: Array<string> } = { objectId: this.currentSeedlingId }
    if (this.isBatch) {
      url = this.$apis.record.insertAllMaintainRecord
      info = { objectIds: [] }
      this.selects.forEach(item => {
        (info.objectIds as Array<string>).push(item.seedlingId)
      })
    }
    this.$axios.post(url, {
      ...info,
      ...formData
    }).then(() => {
      this.$message.success('添加成功')
      this.getData()
    }).finally(() => {
      this.showDialog = false
      this.isBatch = false
      this.tipsShow = false
      this.formData = {}
    })
  }

  closeDialog() {
    this.showDialog = false
    this.isBatch = false
    this.tipsShow = false
    this.formData = {
      maintainDate: '',
      maintainContent: '',
      resourceList: [],
      maintainStatus: '',
      chestDiameter: '',
      qualityLevel: '',
      valueAssessment: '',
      crownWidth: '',
      treeHeight: '',
      configId: []
    }
  }

  onUpdate(seedlingId: string) {
    this.$router.push({
      name: 'ordinarySeedlingUpdate',
      params: { seedlingId }
    })
  }

  onDelete(id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.seedling.deleteSeedling, {
        seedlingId: id
      }).then(() => {
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.page = 1
        this.getData()
      })
    })
  }

  onDetail(seedlingId: string) {
    this.$router.push({
      path: `/ordinarySeedlingList/list/detail${seedlingId}`
    })
  }

  onSearch() {
    this.page = 1
    this.getData()
  }
}
